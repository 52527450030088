/* RESPONSIBLE TEAM: team-knowledge-foundations */
import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  buildURL(modelName, id, snapshot, requestType, query) {
    return query && (query.phrase || query.url_partials?.length > 0)
      ? '/ember/articles/search'
      : this._super('article', id, snapshot, requestType, query);
  },
});
