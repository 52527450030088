/* RESPONSIBLE TEAM: team-knowledge-foundations */
import ApplicationAdapter from '../application';

export default ApplicationAdapter.extend({
  buildURL(modelName, id, snapshot, requestType, query) {
    return this._super('article-folder', id, snapshot, requestType, query);
  },
  deleteRecord(_store, type, snapshot) {
    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'deleteRecord');
    let data = {
      help_center_id: snapshot._attributes.helpCenterId,
    };
    return this.ajax(url, 'DELETE', { data });
  },
});
