/* RESPONSIBLE TEAM: team-knowledge-foundations */
import ApplicationAdapter from './application';

export default class HelpCenterRedirectAdapter extends ApplicationAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return query && query.phrase
      ? '/ember/help_center_redirects/search'
      : super.buildURL(modelName, id, snapshot, requestType, query);
  }
}
